<template>
  <div class="bgimg bgimg-main">
    <v-container>
      <span style="display: inline-block; height: 80vh"></span>

      <v-row justify="space-around" class="pa-12 text-center">
        <v-sheet rounded="xl" width="600" elevation="4">
          <v-col class="mb-4">
            <h1 class="display-2 font-weight-bold my-3">Wij trouwen!</h1>
            <!--h1 class="display-2 font-weight-bold my-3">Wij gaan trouwen!</h1-->
          </v-col>

          <v-col class="mb-4">
            <p class="subheading font-weight-regular text-left">
              In 2018 werden we uitgenodigd om mee op vakantie te gaan met het
              Van der Heijden gezin naar Gambia. Daar hebben we met de hand uit
              hout gesneden stukken fruit meegenomen als souvenir. Op de
              verjaardag van schoonvader Nici vroeg Jeffrey om de hand van
              Suzanne.
            </p>
          </v-col>

          <v-col class="mb-4">
            <v-subheader>Coronamaatregelen</v-subheader>
            <p class="subheading font-weight-regular text-left">
              Houdt deze website in de gaten voor de laatste updates met
              betrekking tot de coronamaatregelen.
            </p>
          </v-col>

          <v-col class="mb-4">
            <v-subheader>Dress code</v-subheader>
            <p class="subheading font-weight-regular text-left">
              De dress code voor onze bruiloft is cocktail chique. Dit betekend
              dat voor de mannen een casual spijkerbroek met een chique colbert
              jasje voldoende is. Meer informatie en inspiratie kun je vinden
              via
              <a
                href="https://letmegooglethat.com/?q=dress+code+cocktail+chique"
                >deze link</a
              >.
            </p>
          </v-col>

          <v-col class="mb-4">
            <v-subheader>Dieetwensen</v-subheader>
            <p class="subheading font-weight-regular text-left">
              Indien je speciale dieetwensen hebt als allergieen of
              intoleranties, stuur dan een mailtje naar onze ceremoniemeester:
              <a href="mailto:bruiloft@jeffreyensuzanne.nl"
                >bruiloft@jeffreyensuzanne.nl</a
              >
            </p>
          </v-col>

          <v-col class="mb-4">
            <v-subheader>Aanmelden</v-subheader>
            <p class="subheading font-weight-regular text-left">
              Gebruik de knop hieronder om je aan te melden voor onze bruiloft.
            </p>
            <register-button />
          </v-col>

          <v-divider class="my-6" />

          <v-tabs fixed-tabs>
            <v-tab>
              <v-icon left> mdi-calendar</v-icon>
              Datum
            </v-tab>
            <v-tab>
              <v-icon left> mdi-clock-time-ten-outline </v-icon>
              Tijd
            </v-tab>
            <v-tab>
              <v-icon left> mdi-map-marker </v-icon>
              Locatie
            </v-tab>

            <v-tab-item>
              <v-card class="mt-6">
                <v-date-picker
                  v-model="date"
                  readonly
                  locale="nl-nl"
                  :first-day-of-week="1"
                  :header-date-format="(x) => 'feb. 2022'"
                  :title-date-format="(x) => 'di 22 februari'"
                ></v-date-picker>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <timeline />
            </v-tab-item>

            <v-tab-item>
              <v-card>
                <v-card-text>
                  <p>
                    Sed aliquam ultrices mauris. Donec posuere vulputate arcu.
                    Morbi ac felis. Etiam feugiat lorem non metus. Sed a libero.
                  </p>

                  <p>
                    Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel,
                    lacus. Aenean tellus metus, bibendum sed, posuere ac, mattis
                    non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse
                    non nisl sit amet velit hendrerit rutrum.
                  </p>

                  <p class="mb-0">
                    Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu.
                    Pellentesque libero tortor, tincidunt et, tincidunt eget,
                    semper nec, quam. Phasellus blandit leo ut odio.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-sheet>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Timeline from "@/components/Timeline";
import RegisterButton from "@/components/RegisterButton.vue";

export default {
  name: "Index",

  components: {
    Timeline,
    RegisterButton,
  },

  data: () => ({
    date: "2022-02-22",
    selectedItem: 1,
    events: [
      {
        time: "19:00",
        title: "Feest!",
        description: "DJ",
      },
      {
        time: "17:30",
        title: "Taart aansnijden",
        description: "",
      },
      {
        time: "16:45 - 17:00",
        title: "Ontvangst avondgasten",
        description: "",
      },
      {
        time: "12:00",
        title: "Lunch",
        description: "",
      },
      {
        time: "10:30",
        title: "Ceremonie",
        description: "",
      },
      {
        time: "10:00",
        title: "Ontvangst daggasten",
        description: "",
      },
    ],
  }),
};
</script>

<style scoped>
.bgimg-main {
  background-image: url("~@/assets/background.jpg");
  min-height: 100%;
}

.bgimg {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
